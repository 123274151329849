























































































































































































































// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import * as Model from "@gigalot/data-models";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
const Subscriber = require("@jetblack/graphql-reconnect-client");
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";

@Component({
  components: {DimssaButton}
})
export default class FeedSchedule extends Vue {
  name = "feedSchedule";
  loading = true;
  search = "";
  rationPlan: Model.RationPlan[] = [];
  feedPlan: any = {};
  feedSchedules: Model.FeedingSchedule = {typename:"FeedinSchedule", guid: "", date : Date.now(),feedingScheduleItems : []};
  selectedFeedSchedules : Model.FeedingScheduleItem[] = [];
  feedResults = [];
  loaders: Model.Loader[] = [];
  selectedLoader: any = 0;
  tempprogress = 20;
  bufferValue = 100;
  tableKey = 0;
  snack = false;
  snackColor= '';
  snackText= '';
  errorText = '';
  errorDialog = false;
  amountToAdd = 0;
  sessionToAddTo = {text: 1, value: 0};
  customFooterProps = {
  itemsPerPageOptions: [10 ,20, 50, 100, 200, -1 ],
  };
  fixedHeader = [{}];
  headers = [
    {
      text: "Kraal",
      value: "kraalId",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50
    },
    {
      text: "Type",
      value: "kraalType",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50
    },
    {
      text: "Animals",
      value: "animals",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50
    },
    {
      text: "Ration",
      value: "ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 50
    },
    {
      text: "Feed Allocated",
      value: "feedAllocated",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100
    },
    {
      text: "Actual Fed",
      value: "actualFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100
    },
    {
      text: "Tractor",
      value: "tractorAllocated",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 30
    },
    {
      text: "Progress",
      value: "progress",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 150
    },
    { text: "", value: "data-table-expand", width:20 }
  ];
  expanded = [];
  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedSchedule);
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedScheduleSocket);
    this.loaders = lodash.cloneDeep(this.$store.getters["storage"]().Loaders) as Model.Loader[];
  }
  get moment() {
    return this.$store.state.moment;
  }
getSessions(item:Model.FeedingScheduleItem){
  return item.feedAllocations.map((alloc,index) => {
    return {text: index+1, value: index}
  });
}
getAnimalCount(kraalId:string){
  let itemPlan = lodash.find((this.feedPlan as Model.FeedingPlan).FeedingPlanItems, { kraalId: kraalId }); //TODO: replace kraal ID with Kraal guid
 return itemPlan?.numberAnimals;
}

destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
  }


  
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  subscriptions = `subscription($guid:String!) {
     feedSchedule(guid:$guid){
       typename
          guid
          feedingScheduleItems{
            typename
            guid
            kraalId
            kraalGuid
            ration
            rationGuid
            feedAllocated
            activeFeed
            actualFed
            sequence
            tractorAllocated
            priority
            feedAllocations{
              typename
              feedAllocated
              actualFed
              active
              allocated
              percentage
              scheduledAllocatedFeed
            }
          }
        }
    }`;

  variables = {guid:this.$store.state.user.location.guid };
  operationName = null;

   graphQLSubscriber: any = undefined;
   graphQLUnsubscribe: any = undefined;


async getFeedScheduleSocket(){
 if (this.$store.state.useP2PConn){
      await this.getOnlineFeedScheduleSocket();
    }
    else{
      await this.getOnsiteFeedScheduleSocket();
    }
  }
 
  async getOnsiteFeedScheduleSocket() {
   this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
             if (error.event.type === "close") {
            } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connected");
          this.graphQLUnsubscribe = subscribe(this.subscriptions, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              // Normal closure
              this.$store.state.wsDialogVisible = false;
              return;
            }
            if (error) {
              console.log("error subscribing");
                 if (error.event.type === "close") {
              } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error);
               }
            }
            this.$store.state.wsDialogVisible = false;
            console.log("receiving data");
             this.feedSchedules = data.feedSchedule;
            this.loading = false;
          });
        } catch (err) {
          }
      },
      15000,
      3,
      "graphql-ws"
    );
  }


  async getOnlineFeedScheduleSocket() {

  let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };

    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables,
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed"),
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") {
        } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");

      console.log(data);
     this.feedSchedules = data.feedSchedule;
    this.loading = false;
    });
  }

  subloading = false;

  async getFeedResults(item: any, value: boolean) {
    let kraalGuid = item.item.kraalGuid;
    let results: Model.FeedingResult[] = [];
    try {
      console.log("FeedResults( " + kraalGuid + " )");
      this.subloading = true;
      let query = {
        gql: `query FeedResult($guid: String!, $kraalGuid: String!){
        FeedResult(guid:$guid, kraalGuid: $kraalGuid){
          timestamp
          latitude
          longitude
          amountFed
          kraalID
          kraalGuid
          rationGuid
          operatorID
          tractorID
          trailerID
          rationFed
        }
      }`,
        variables: {
          guid: this.$store.state.user.location.guid,
          kraalGuid: kraalGuid
        }
      };
      let json = await this.$store.dispatch("graphQl", query);
     this.feedResults = json.data.FeedResult;
    } catch (err) {
      console.log("getFeedResult -> error: " + err);
    }
    this.subloading = false;
  }

  async getFeedSchedule() {
    try {
      console.log("FeedSchedule()");
      this.loading = true;
      let gql = `query($guid:String!){
        FeedSchedule(guid:$guid){
       typename
          guid
          feedingScheduleItems{
            typename
            guid
            kraalId
            kraalGuid
            ration
            rationGuid
            feedAllocated
            actualFed
            sequence
            activeFeed
            priority
            tractorAllocated
            feedAllocations{
              typename
              feedAllocated
              actualFed
              active
              allocated
              percentage
              scheduledAllocatedFeed
            }
          }
        }
        FeedPlan(guid:$guid) {
          FeedingPlanItems{
            kraalId
            numberAnimals
            currentRation{
              colour
            }
          }
        }
         RationPlans(guid:$guid) {
          typename
          guid
          creationDate
          rationItems{
            typename
            guid
            ration
            defaultDays
            colour
            order
          }
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid } });
       this.feedPlan = json.data.FeedPlan
      this.feedSchedules = json.data.FeedSchedule;
      this.rationPlan = json.data.RationPlan;
    } catch (err) {
      console.log("getFeedSchedule -> error: " + err);
    }
    this.loading = false;
  }

  getKraalType(guid:string){
    let kraals = this.$store.getters[`storage`]().Kraals as Model.Kraal[];
    let kraal = lodash.find(kraals,{guid:guid});
    if (kraal)
    return kraal.kraalType;
  }

  getKraalName(guid:string){
      let kraals = this.$store.getters[`storage`]().Kraals as Model.Kraal[];
    let kraal = lodash.find(kraals,{guid:guid});
    if (kraal)
    return kraal.kraalId;
  }

 getTrailerName(guid:string){
    let trailers = this.$store.getters[`storage`]().Trailers as Model.Trailer[];
    let trailer = lodash.find(trailers,{electronicID:guid});
    if (trailer)
    return trailer.wagonRegistration;
  }

   getTractorName(guid:string){
      let tractors = this.$store.getters[`storage`]().Tractors as Model.Tractor[];
    let tractor = lodash.find(tractors,{electronicID:guid});
    if (tractor)
    return tractor.registrationNumber;
  }

  
   getOperatorName(guid:string){
      let operators = this.$store.getters[`storage`]().Operators as Model.Operator[];
    let operator = lodash.find(operators,{ electronicID: guid });
    if (operator)
    return operator.operatorFullName;
  }

  getColor(kraalId: string) {
        let itemPlan = lodash.find(this.feedPlan.FeedingPlanItems, { kraalId: kraalId }); //TODO: replace kraal ID with Kraal guid
       return itemPlan.currentRation.colour;
     }

  isUnfed(kraalId: string) {
    let itemPlan = lodash.find((this.feedPlan as Model.FeedingPlan).FeedingPlanItems, { kraalId: kraalId }); //TODO: replace kraal ID with Kraal guid
    let feedItem = lodash.find((this.feedSchedules as Model.FeedingSchedule).feedingScheduleItems, { kraalId: kraalId });
    if (itemPlan?.numberAnimals) {
      if (feedItem?.actualFed) {
        //there is something fed
        return false;
      }
      else return true;
    }
    return false;
  }

  isPartialfed(kraalId: string) {
    //get feedschedule
    //get animal count
    //if animals and feed not complete
    let itemPlan = lodash.find((this.feedPlan as Model.FeedingPlan).FeedingPlanItems, { kraalId: kraalId }); //TODO: replace kraal ID with Kraal guid
    let feedItem = lodash.find((this.feedSchedules as Model.FeedingSchedule).feedingScheduleItems, { kraalId: kraalId });
    if (itemPlan?.numberAnimals) {
      if (feedItem?.feedAllocated) {
        if (feedItem?.actualFed < feedItem?.feedAllocated) {
        //there is something fed
        return true;
        }
      }
    }
    return false;
  }
  
  backColor(feedResult: Model.FeedingResult, scheduled: Model.FeedingScheduleItem){
      //if wrong ration fed
      if (feedResult.rationGuid != scheduled.ration)
      return "incorrect-ration";
      else 
      return "correct";
  }
  page = 1;
  forceRerender() {
    
    let pageHolder = this.page;
    console.log(pageHolder);
    this.tableKey += 1;
    this.page = pageHolder;
    console.log(pageHolder);
  }


  cancel(){
      this.snack = true
     this.snackColor = 'blue lighten-1'
     this.snackText = 'Canceled'
  }
  close(){}
  save(item:any){this.updateServer(item)}

  async saveAddFeedToAllocation(item:Model.FeedingScheduleItem | undefined) {
    if (this.amountToAdd > 0){
      if (item){
      this.selectedFeedSchedules = [item];
      await this.mergeSessions();
      await this.getFeedSchedule();
      item = this.feedSchedules.feedingScheduleItems.find((f:Model.FeedingScheduleItem) => { if (f.guid === item?.guid) return f;});
      if (item){
      item.feedAllocated += this.amountToAdd;
      item.feedAllocations[0].feedAllocated += this.amountToAdd;
    }
    this.updateServer(item);
  }
}
}

  async updateServer(editedItem?: any) {
    console.log(editedItem);
    if (editedItem) {
      if (this.selectedLoader){
        
      }
      try {
        console.log("updateServer()");
        let guid_string = JSON.stringify(editedItem);
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation addFeedSchedule($guid:String!, $input: FeedingScheduleItemInput!, $tractorId: String!) {
            addFeedSchedule(guid:$guid, input: $input, tractorId:$tractorId) 
          }`,
            variables: { guid:this.$store.state.user.location.guid ,
            input: editedItem,
            tractorId: this.selectedLoader?.tractor?.electronicID ?? "" }
          },
          { root: true }
        );
        
         this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err} ${editedItem.kraalId}`
    
        throw err;
      }
    }
  }


  mergeSessions(){
   for (let item of this.selectedFeedSchedules){
    this.putMergeSession(item.kraalGuid)
   }

  }
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  async putMergeSession(guid:string) {

    try {
        console.log("updateServer()");
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation mergeSession($guid:String!, $kraalGuid: String!) {
            mergeSession(guid:$guid, kraalGuid: $kraalGuid) 
          }`,
            variables: { guid:this.$store.state.user.location.guid ,
                         kraalGuid: guid,
            }
          },
          { root: true }
        );
        
         this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err} `
    
        throw err;
      }
    }
}
