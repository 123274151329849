var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Mixing Results")]),_c('v-spacer')],1),_c('v-data-table',{class:_vm.themeClass,attrs:{"headers":_vm.headers,"items":_vm.mixResults,"item-key":"MixID","items-per-page":50,"show-expand":""},scopedSlots:_vm._u([{key:"item.ActualWeight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ActualWeight.toFixed(0))+" ")]}},{key:"item.TractorID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTractorName(item.TractorID))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dark":"","dense":"","colspan":headers.length},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Sequence")]),_c('th',{staticClass:"text-left"},[_vm._v("Material")]),_c('th',{staticClass:"text-left"},[_vm._v("Mass Loaded")]),_c('th',{staticClass:"text-left"},[_vm._v("Start Time")]),_c('th',{staticClass:"text-left"},[_vm._v("End Time")])])]),_c('tbody',_vm._l((item.LoadIngredients),function(mixes){return _c('tr',{key:mixes.MixSequenceNumber},[_c('td',[_vm._v(_vm._s(mixes.MixSequenceNumber))]),_c('td',[_vm._v(_vm._s(mixes.MaterialCode))]),_c('td',[_vm._v(_vm._s(mixes.ActualWeight.toFixed(0)))]),_c('td',[_vm._v(_vm._s(_vm.moment(parseInt(mixes.LoadStartTime)).format("hh:mm:ss")))]),_c('td',[_vm._v(_vm._s(_vm.moment(parseInt(mixes.LoadEndTime)).format("hh:mm:ss")))])])}),0)]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }